// @flow

import React from 'react';
import ReactDOM from 'react-dom';
import { loadableReady } from '@loadable/component';
import { App } from 'client_components/app/App';

if (typeof window !== 'undefined' && window.RiseArt && window.RiseArt.isSSR) {
  loadableReady(() => {
    ReactDOM.hydrate(<App />, document.getElementById('application'));
  });
} else {
  ReactDOM.render(<App />, document.getElementById('application'));
}
