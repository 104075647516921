// @flow

import { v4 } from 'uuid';
import find from 'lodash/find';
import findLast from 'lodash/findLast';
import uniqWith from 'lodash/uniqWith';
import debounce from 'lodash/debounce';
import { meta as META_ENUM } from 'Enum';
import { GUI_PROPERTIES } from 'shared_models/Gui';
import { DOMTagUtils } from 'shared_services/riseart/utils/DOMTagUtils';
import {
  META_DATA_HANDLERS,
  META_VALUE_BY_TYPE_HANDLER,
  PERMANENT_TAGS,
  COMBINED_META_KEYS,
  META_TYPES_MAPPER,
  META_PRIORITY,
  VALID_META_KEYS,
  META_SUBSCRIBER_NAME,
  METADATA_SEPARATORS,
} from 'shared_services/riseart/meta/constants';
import {
  metaValueTransformer,
  checkUniqueTags,
  findMetaRedirect,
} from 'shared_services/riseart/meta/utils';
import { LocationManager } from 'shared_services/riseart/url/Location';

const { METATYPE, MERGE_CONDITION, ATTRIBUTE_CONTENT } = META_ENUM;
const PUSH_DEBOUNCE_DELAY = 50;
const DEFAULT_PRIORITY = 0;
const DEFAULT_TIMEOUT = 0;
const SUBSCRIBER_STATE = { pending: 'pending', error: 'error', resolved: 'resolved' };
const MULTIPLE_TAGS_METAS = [METATYPE.LINK_HREFLANG, METATYPE.LINK_HREFLANG_REDIRECT];

// pageTitle is a special type of meta, which does not require configuration in META_TYPES_MAPPER,
// so instead it is set here. Also it can be used to set separator if not set in META_TYPES_MAPPER
const SEPARATORS_BY_METATYPE = {
  pageTitle: METADATA_SEPARATORS.SPACE,
};

/**
 * MetaService
 *
 * Service to handle all meta data on page including pageTitle which is stored in redux store
 */
const MetaService = {
  meta: [],
  subscribersTimeouts: {},
  actionGuiUpdate: null,
  actionSubscribersResolved: null,
  applicationStoreUpdateRedirects: null,
  actionReset: null,
  helperProps: {},
  subscriptions: [],
  config(
    helperProps: Object = {},
    actionGuiUpdate?: Function = () => {},
    actionSubscribersResolved?: Function,
    applicationStoreUpdateRedirects?: Function,
    actionReset?: Function,
  ) {
    this.actionGuiUpdate = actionGuiUpdate;
    this.actionSubscribersResolved = actionSubscribersResolved;
    this.applicationStoreUpdateRedirects = applicationStoreUpdateRedirects;
    this.actionReset = actionReset;
    this.helperProps = helperProps;

    return this;
  },
  init(
    metaSubscriptions: Array<Object> = [],
    initialMeta?: ?Array<Object>,
    updatedHelperProps: Object = {},
  ) {
    if (initialMeta) {
      this.meta = [...initialMeta];
    }

    this.helperProps = { ...this.helperProps, ...updatedHelperProps };

    // Clear subscribers timeouts
    if (this.subscribersTimeouts) {
      Object.keys(this.subscribersTimeouts).forEach((timeoutKey: string) =>
        clearTimeout(this.subscribersTimeouts[timeoutKey]),
      );
      this.subscribersTimeouts = {};
    }

    this.subscriptions = metaSubscriptions.map((i) => ({
      priority: DEFAULT_PRIORITY,
      timeout: DEFAULT_TIMEOUT,
      state: SUBSCRIBER_STATE.pending,
      ...i,
    }));

    this.subscriptions.forEach((i) => {
      if (i.timeout > 0) {
        this.subscribersTimeouts[i.name] = setTimeout(() => {
          this.resolveSubscription(i.name);
          this.notify();
        }, i.timeout);
      }
    });

    return this;
  },
  permanentMetas(): Array<Object> {
    return [{ type: METATYPE.LINK_HREFLANG, value: 'custom' }];
  },
  add(data: Array<Object>, subscriberName?: string) {
    const id = v4();
    const subscription =
      (subscriberName && find(this.subscriptions, { name: subscriberName })) || {};

    // Subscriber was not found in the available subscribers for the instance
    if (subscriberName && !subscription.name) {
      return;
    }

    if ([SUBSCRIBER_STATE.error, SUBSCRIBER_STATE.resolved].indexOf(subscription.state) > -1) {
      return;
    }

    const { priority: subscriberPriority = 0, condition } = subscription;
    const { intl, match, location, pageRouteConfig } = this.helperProps;

    data = data.reduce((accummulator, i) => {
      const hasMultipleTags = this.hasMultipleTags(i.type);
      const value = META_VALUE_BY_TYPE_HANDLER[i.type]
        ? META_VALUE_BY_TYPE_HANDLER[i.type](metaValueTransformer(i.value), {
            intl,
            match,
            location,
            pageRouteConfig,
          })
        : META_VALUE_BY_TYPE_HANDLER.default(metaValueTransformer(i.value), { intl });

      if (Array.isArray(value) && hasMultipleTags) {
        return [
          ...accummulator,
          ...value.map((val) => ({
            subscriber: subscriberName || null,
            priority: subscriberPriority,
            condition,
            ...i,
            attributes: val,
            value: val.href,
            hasMultipleTags,
          })),
        ];
      }

      return [
        ...accummulator,
        {
          subscriber: subscriberName || null,
          priority: subscriberPriority,
          condition,
          ...i,
          value,
        },
      ];
    }, []);

    // check for already existing items with same type and priority and remove them
    this.meta = this.meta.filter(
      ({ priority, type, hasMultipleTags }) =>
        (hasMultipleTags && find(data, { type }) && priority > subscriberPriority) ||
        (hasMultipleTags && !find(data, { type })) ||
        (!hasMultipleTags && !find(data, { priority, type })),
    );

    // add new meta data only if true values are added
    this.meta.push(...data.filter((i) => !!i.value).map((i) => ({ id, ...i })));

    if (subscriberName && subscription.timeout <= 0) {
      this.resolveSubscription(subscriberName);
    }

    if (this.subscribersTimeouts[subscription.name]) {
      clearTimeout(this.subscribersTimeouts[subscription.name]);
    }

    subscription.timeout > 0
      ? (this.subscribersTimeouts[subscription.name] = setTimeout(() => {
          this.resolveSubscription(subscription.name);

          this.notify();
        }, subscription.timeout))
      : this.pushMetaData();

    return id;
  },
  removeByType(type: string) {
    const filteredMeta = this.meta.filter((i) => i.type !== type);

    if (this.meta.length !== filteredMeta.length) {
      // deletes the actual tag
      if (!this.helperProps.isSSR) {
        this.deleteTag({ metaType: type, ...(META_TYPES_MAPPER[type] || {}) });
      }
      // update the meta list
      this.meta = [...filteredMeta];
    }
  },
  reset() {
    // no need to delete tags from DOM on server because in this case
    // it does not manipulate the dom but extracts all data as html markup at once
    if (!this.helperProps.isSSR) {
      this.selectData(this.meta)
        .filter((i) => PERMANENT_TAGS.indexOf(i.tag) === -1)
        .forEach(this.deleteTag);

      if (typeof this.actionReset === 'function') {
        this.actionReset();
      }
    }
    this.meta = [];

    return this;
  },
  deleteTag(meta: Object) {
    if (!meta || meta.excludeFromDom) {
      return;
    }

    if (MULTIPLE_TAGS_METAS.indexOf(meta.metaType) > -1) {
      DOMTagUtils.deleteMultipleTags(meta.metaType, document.head);
    } else {
      DOMTagUtils.deleteTag(meta, document.head);
    }
  },
  prepareDataBeforeUpdate() {
    const hasNoIndexTag = this.has({
      type: METATYPE.META_ROBOTS,
      value: ATTRIBUTE_CONTENT.NO_INDEX,
    });

    const meta = this.meta.filter(({ type }) => {
      return !(type === METATYPE.LINK_CANONICAL && hasNoIndexTag);
    });

    return uniqWith<*, *>(
      this.selectData(meta).reverse(),
      (a, b) =>
        (!a.hasMultipleTags && a.metaType === b.metaType) ||
        (checkUniqueTags[a.metaType] && checkUniqueTags[a.metaType](a, b)),
    );
  },
  selectData(meta: Array<Object> = []): Array<Object> {
    const copiedMeta = [...meta];
    const combinedValues: Array<Object> = copiedMeta
      .sort((a, b) => a.priority - b.priority)
      .reduce(
        (
          result,
          { type, value, attributes = null, condition: mergeCondition, hasMultipleTags, priority },
        ) => {
          let content;
          const tagConfig = META_TYPES_MAPPER[type] || {};

          if (COMBINED_META_KEYS.indexOf(type) === -1) {
            content = value;
          } else {
            const currentValue = (findLast(result, { metaType: type }) || {}).content;
            if (mergeCondition === MERGE_CONDITION.CONCAT) {
              const separator =
                tagConfig.separator || SEPARATORS_BY_METATYPE[type] || METADATA_SEPARATORS.PIPE;
              content = currentValue ? `${value}${separator}${currentValue}` : value;
            } else if (mergeCondition === MERGE_CONDITION.MERGE) {
              content = value || currentValue;
            } else {
              content = value;
            }
          }

          result.push({
            metaType: type,
            hasMultipleTags,
            priority,
            ...tagConfig,
            ...(META_DATA_HANDLERS[tagConfig.tag] &&
              META_DATA_HANDLERS[tagConfig.tag](content, {
                ...tagConfig.attributes,
                ...attributes,
              })),
            content,
          });

          return result;
        },
        [],
      );

    // collect metaContext in object where each key is the meta type
    const metaContentByKey: Object = combinedValues.reduce(
      (result, i) => ({ ...result, [i.metaType]: i.content }),
      {},
    );

    return combinedValues.map((meta: Object) => {
      if (!meta.collectFromOtherMetas) {
        return meta;
      }

      // if meta has collectFromOtherMetas defined then it will collect the data from other meta types
      const collectedContent = meta.collectFromOtherMetas(meta.content, metaContentByKey);

      return {
        ...meta,
        content: collectedContent,
        textNode: JSON.stringify(collectedContent),
      };
    });
  },
  // actionGuiUpdate is an actual redux dispatch action.
  // if it is passed to the service in init, then it will call the action which will update the store
  updatePageTitle(title: string) {
    if (this.actionGuiUpdate) {
      this.actionGuiUpdate(GUI_PROPERTIES.PAGE_TITLE, title);
    }
  },
  pushMeta: function pushMeta() {
    const meta = this.prepareDataBeforeUpdate();

    // Delete multiple tag nodes first injecting new nodes
    if (!this.helperProps.isSSR) {
      meta.forEach(({ metaType, excludeFromDom }) => {
        if (!excludeFromDom && MULTIPLE_TAGS_METAS.indexOf(metaType) > -1) {
          DOMTagUtils.deleteMultipleTags(metaType, document.head);
        }
      });
    }

    meta.forEach((meta) => {
      meta.metaType !== METATYPE.PAGE_TITLE
        ? !this.helperProps.isSSR &&
          !meta.excludeFromDom &&
          DOMTagUtils.updateTag(meta, document.head)
        : this.updatePageTitle(meta.content || '');
    });

    if (typeof this.actionSubscribersResolved === 'function') {
      const redirects = this.extractRedirectMetas(meta);

      if (this.helperProps.isSSR) {
        const foundRedirect = findMetaRedirect(redirects, {
          currentParams: this.helperProps.match && this.helperProps.match.params,
          currentLocale: this.helperProps.currentLocale,
          userLocale: this.helperProps.userLocale,
          location: this.helperProps.location,
          routeConfigPath:
            this.helperProps.pageRouteConfig && this.helperProps.pageRouteConfig.path,
        });

        if (foundRedirect && foundRedirect.url) {
          LocationManager.redirect(
            foundRedirect.url,
            foundRedirect.status && { status: foundRedirect.status },
          );
        }
      }

      this.applicationStoreUpdateRedirects(redirects);
    }

    return meta;
  },
  /*
  This functions is called with debounce, so if many resources try to pushMeta
  at same time, it will be called only once with all collected data.
  */
  // $FlowFixMe
  pushMetaDebounced: debounce(function pushMetaDebounced() {
    return this.pushMeta();
  }, PUSH_DEBOUNCE_DELAY),
  pushMetaData() {
    // pushMeta method is called differently in SSR and client render.
    // On client render the function is debounced to optimize and reduce
    // the call to the function (also updated in the state)
    // On the othr hand on the server it has to be called imidiately in order to update the data
    return this.helperProps.isSSR ? this.pushMeta() : this.pushMetaDebounced();
  },
  // Extract redirects (locale and canonical) from meta
  extractRedirectMetas(metas: Object[]): { locale: string | null, canonical: string | null } {
    return metas.reduce(
      (accumulator, item) => {
        if (item.metaType === META_ENUM.METATYPE.LINK_HREFLANG_REDIRECT) {
          // Set empty object if first locale redirect is set and accumulator.locale is null
          if (accumulator.locale === null) {
            accumulator.locale = {};
          }
          accumulator.locale[item.attributes.hreflang] = item.attributes.href;
        } else if (item.metaType === META_ENUM.METATYPE.LINK_CANONICAL_REDIRECT) {
          accumulator.canonical = item.content;
        }

        return accumulator;
      },
      { locale: null, canonical: null },
    );
  },
  extract() {
    return this.prepareDataBeforeUpdate()
      .filter((i) => i.tag && !i.excludeFromDom)
      .map(DOMTagUtils.renderToString)
      .join('\n');
  },
  extractAsElements() {
    return this.prepareDataBeforeUpdate()
      .filter((i) => i.tag && !i.excludeFromDom)
      .map(DOMTagUtils.renderToElements);
  },

  getMeta() {
    return this.meta;
  },
  getMetaByType(type: string): Object | Array<Object> {
    const meta = this.selectData(this.meta.filter((i) => i.type === type));
    const maxPriority = meta.reduce(
      (maxPriority, { priority }) => (maxPriority > priority ? maxPriority : priority),
      0,
    );
    const foundItems = meta.filter((item) => item.priority === maxPriority);

    return this.hasMultipleTags(type) ? foundItems : foundItems[0];
  },
  getSubscriptions() {
    return this.subscriptions;
  },
  notify() {
    if (!this.areAllResolved()) {
      return;
    }

    this.pushMetaData();

    if (typeof this.actionSubscribersResolved === 'function') {
      this.actionSubscribersResolved({
        subscribers: this.subscriptions,
        pageMeta: {
          pageTitle: ((this.getMetaByType('pageTitle') || {}).content || {}).text,
          metaTitle: (this.getMetaByType('metaTitle') || {}).content,
          metaDescription: (this.getMetaByType('metaDescription') || {}).content,
          metaKeywords: (this.getMetaByType('metaKeywords') || {}).content,
        },
      });
    }
  },
  updateState(subscriber: string, nextState: string) {
    this.subscriptions = this.subscriptions.map((i) =>
      i.name === subscriber ? { ...i, state: nextState } : i,
    );
    return this.subscriptions;
  },
  resolveSubscription(subscriber: string) {
    clearTimeout(this.subscribersTimeouts[subscriber]);
    this.updateState(subscriber, SUBSCRIBER_STATE.resolved);
  },
  errorSubscription(subscriber: string) {
    const updatedSubscriptions = this.updateState(subscriber, SUBSCRIBER_STATE.error);
    const subscription = find(updatedSubscriptions, { name: subscriber });

    if (subscription && subscription.timeout > 0) {
      clearTimeout(this.subscribersTimeouts[subscriber]);
      this.notify();
    }
  },
  resolveAllSubscriptions() {
    this.subscriptions.map((i) => ({ ...i, state: SUBSCRIBER_STATE.resolved }));
    return this.subscriptions;
  },
  unresolveSubscription(subscriber: string) {
    this.updateState(subscriber, SUBSCRIBER_STATE.pending);
  },
  isSubscriberResolved(subscriberName: string) {
    return this.subscriptions.some(
      ({ name, state }) => name === subscriberName && state === SUBSCRIBER_STATE.resolved,
    );
  },
  areAllResolved() {
    return !find(this.subscriptions, { state: SUBSCRIBER_STATE.pending });
  },
  has(data: Object) {
    const foundItem = this.meta.filter((metaItem) => {
      return !Object.keys(data).some((field) => metaItem[field] !== data[field]);
    });

    return !!foundItem.length;
  },
  hasMultipleTags(type: string): boolean {
    return MULTIPLE_TAGS_METAS.indexOf(type) > -1;
  },
};

export { MetaService, META_PRIORITY, VALID_META_KEYS, META_SUBSCRIBER_NAME };
